<template>
  <div>
    <div v-if="isDev" class="top-banner d-flex align-items-center justify-content-center">
      <p class="title">STAGING</p>
    </div>
    <div class="d-flex justify-content-between align-items-center border-bottom" style="height: 4rem">
      <span class="d-flex align-items-center h-100">
        <TenantSelect class="shadow tenant-select border-bottom" />
        <Search />
      </span>
      <span class="d-flex align-items-center mx-5">
        <NotificationsBadge />
        <ProfileBadge />
      </span>
    </div>
  </div>
</template>

<script>
import config from '@/config';

import Search from './Search';
import NotificationsBadge from './NotificationsBadge';
import ProfileBadge from './ProfileBadge';
import TenantSelect from './TenantSelect';

export default {
  components: { ProfileBadge, TenantSelect, Search, NotificationsBadge },
  setup() {
    return {
      isDev: config.NODE_ENV !== 'production',
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.top-banner {
  height: 1rem;
  background: repeating-linear-gradient(-55deg, #ff9d2a, #ff9d2a 10px, #f38300 10px, #f38300 20px);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5000;
  border-bottom: 1px solid #a65900;
  .title {
    font-size: 12px;
    letter-spacing: 0.4em;
    color: #fff;
    font-weight: bold;
  }
}
.tenant-select {
  height: $tenant-header-height;
}
.tenant-select:after {
  background-color: #fff;
  height: 0.8rem;
  content: '';
  position: absolute;
  bottom: -0.9rem;
  left: -1rem;
  right: -1rem;
}
</style>
