<template>
  <div>
    <hr v-if="isSupplier && route === 'permissions'" class="my-4 mx-1 divider" />
    <hr v-else-if="route === 'contacts'" class="my-4 mx-1 divider" />
    <div @mouseenter="show" @mouseleave="hide">
      <router-link
        ref="menuLink"
        class="navigation-menu-link d-flex justify-content-start align-items-center text-typography-primary text-decoration-none overflow-hidden rounded"
        :class="{ ['mx-1']: addMargin, expanded, ['withSubLinks']: hasSubLinks, ['bg-secondary']: innerHover }"
        style="height: 40px"
        :to="{ name: route }"
      >
        <div class="position-relative mx-2">
          <el-tooltip
            :key="`${route}-tooltip`"
            :disabled="expanded"
            :content="$t(text)"
            effect="dark"
            placement="left"
            :offset="tooltipOffset"
            :enterable="false"
          >
            <component :is="icon" />
          </el-tooltip>
        </div>
        <div class="d-flex align-items-center gap-2">
          <p class="text-nowrap">{{ $t(text) }}</p>
        </div>
        <ChevronIcon
          v-if="hasSubLinks"
          :direction="direction"
          class="text-disabled"
          :class="{ ['me-auto']: $direction === 'rtl', ['ms-auto']: $direction === 'ltr' }"
        />
      </router-link>
      <ul
        v-if="hasSubLinks"
        class="dropdown-menu inner-navigation-menu position-absolute bg-transparent py-0 m-0 border-0 shadow rounded"
        @mouseenter="innerHover = true"
        @mouseleave="innerHover = false"
      >
        <li v-for="link in subRoutes" :key="link.route">
          <MenuLink :key="link.route" v-bind="link" :add-margin="false" class="m-0 bg-white rounded" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { ChevronIcon, HomeIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import { Dropdown } from 'bootstrap';
import { BUSINESS_TYPE } from '@/modules/users/constants';

export default {
  name: 'MenuLink',
  components: { ChevronIcon, HomeIcon },
  props: {
    route: { type: String, required: true },
    subRoutes: { type: Array, default: () => [] },
    text: { type: String, required: true },
    icon: { type: Object, default: () => HomeIcon },
    expanded: { type: Boolean, default: true },
    addMargin: { type: Boolean, default: true },
  },
  setup() {
    const { currentTenant } = useTenancy();

    const isSupplier = computed(() => currentTenant.value?.type === BUSINESS_TYPE.SUPPLIER);

    return { isSupplier };
  },
  data() {
    return {
      dropdown: '',
      innerHover: false,
      closeDropdownTimeout: null,
    };
  },
  computed: {
    tooltipOffset() {
      if (this.hasSubLinks) return this.$direction === 'ltr' ? -30 : 30;
      return 0;
    },
    direction() {
      if (this.$direction === 'rtl') {
        return 'left';
      } else {
        return 'right';
      }
    },
    hasSubLinks() {
      return !!this.subRoutes.length;
    },
  },
  mounted() {
    if (this.hasSubLinks)
      this.dropdown = new Dropdown(this.$refs.menuLink.$el, {
        popperConfig: function () {
          return {
            placement: 'auto-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ],
          };
        },
      });
  },
  methods: {
    show() {
      if (this.hasSubLinks) {
        if (this.closeDropdownTimeout) clearTimeout(this.closeDropdownTimeout);
        this.dropdown.show();
      }
    },
    hide() {
      if (this.hasSubLinks)
        this.closeDropdownTimeout = setTimeout(() => {
          if (!this.innerHover) this.dropdown.hide();
        }, 150);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.inner-navigation-menu {
  z-index: 2001;
  width: $navigation-menu-width-expanded;
}

.navigation-menu-link {
  outline: none;

  &.router-link-active {
    background: $secondary;
    color: $primary !important;
  }
  &:hover {
    background: $secondary;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-active {
  transition-delay: 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.divider {
  color: $outline;
  opacity: 1;
}
</style>
