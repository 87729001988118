<template>
  <div v-loading="loading" class="w-100 h-100">
    <div class="w-100 h-100 d-none d-md-block">
      <Header class="navigation-header" />
      <div class="navigation-view overflow-hidden d-flex">
        <NavigationMenu v-if="!loading" class="flex-shrink-0" />
        <div id="router-container" class="w-100 bg-white overflow-auto flex-fill">
          <router-view v-if="!loading" />
        </div>
      </div>
    </div>

    <div class="d-md-none">
      <h6 class="text-center mt-7">{{ $t('mobileNotSupported') }}</h6>
    </div>
    <ProductModal />
    <ChatModal
      v-if="chatChannelName && chatChannelUrl"
      class="position-absolute bottom-0 end-0"
      :channel-url="chatChannelUrl"
      :channel-name="chatChannelName"
      :business-name="chatBusinessName"
      :is-channel-enabled="isChannelEnabled"
      @chat-close="closeChatModal"
      @messages-read="onChannelReadMessage"
      @new-chat-message="onChannelNewMessage"
    />
  </div>
</template>

<script type="text/javascript">
import { watch } from 'vue';

import { useTenancy } from '@/modules/auth';
import ProductModal from '@/modules/productModal/components/ProductModal';
import { ChatModal, useChatModal } from '@/modules/chatModal';
import { useChannel } from '@/modules/reconciliation';

import { Header, NavigationMenu } from './components';
import { useAnalytics } from '@/analytics/compositions/useAnalytics';

export default {
  components: { ProductModal, ChatModal, NavigationMenu, Header },
  props: { tenantId: { type: String, required: true } },
  setup() {
    const { chatChannelName, chatChannelUrl, chatBusinessName, isChannelEnabled, closeChatModal } = useChatModal();
    const { onChannelReadMessage, onChannelNewMessage } = useChannel();
    const { currentTenant, loading } = useTenancy();
    watch(
      currentTenant,
      (tenant) => {
        document.title = tenant ? `CLARITY | ${tenant.name}` : 'CLARITY';
      },
      { immediate: true }
    );
    useAnalytics();

    return {
      loading,
      chatChannelName,
      chatChannelUrl,
      chatBusinessName,
      isChannelEnabled,
      closeChatModal,
      onChannelReadMessage,
      onChannelNewMessage,
    };
  },
  i18n: {
    messages: {
      en: {
        mobileNotSupported: 'Mobile version coming very shortly. Please view this site on a computer.',
      },
      he: {
        mobileNotSupported: 'גרסת מובייל מגיעה בקרוב. אנא צפו באתר זה דרך המחשב.',
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.navigation-header {
  height: $tenant-header-height;
}
.navigation-view {
  height: calc(100% - #{$tenant-header-height});
}
</style>
