<template>
  <div class="notification-container" @click="$emit('notificationClicked', id)">
    <div class="icon-container">
      <DeliveryIcon class="delivery-icon" />
    </div>
    <div class="content-container">
      <div class="title-container">
        <p class="fw-bold">{{ title }}</p>
        <div class="time-ago-container" :class="{ read }">
          <div>
            {{ formatTimeAgo(createdAt) }}
          </div>
          <div v-if="!read" class="dot"></div>
        </div>
      </div>
      <div class="description-container">{{ description }}</div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import { DeliveryIcon } from '@/assets/icons';

export default {
  name: 'Notification',
  components: { DeliveryIcon },
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    read: { type: Boolean, required: true },
    createdAt: { type: Number, required: true },
    domain: { type: String, required: true },
    data: { type: Object, required: true },
  },
  methods: {
    formatTimeAgo: (ms) => new DateTime.fromMillis(ms).toRelative(),
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.notification-container {
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 8px;
  padding: 14px 16px;
  border-top: 1px solid $outline;
  cursor: pointer;

  &:hover {
    background: $secondary;
  }

  &:active {
    background: #d6dfe5;
  }
}

.icon-container {
  display: flex;
  flex-basis: 50px;
  justify-content: center;
  align-items: center;
  background: $secondary;
  border-radius: 50%;
  height: 40px;

  .delivery-icon {
    color: $typography-secondary;
  }
}

.title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  p {
    font-size: 1em;
    line-height: 100%;
    margin: 0;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
}

.description-container {
  font-size: 12px;
  word-break: normal;
}

.time-ago-container {
  font-size: 12px;
  color: $primary;
  display: flex;
  align-items: center;
  font-weight: normal;

  &.read {
    color: $typography-secondary;
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $primary;
    display: flex;
    margin-left: 4px;

    [dir='rtl'] & {
      margin-right: 4px;
      margin-left: 0;
    }
  }
}
</style>
