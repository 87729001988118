<template>
  <div v-if="user" class="d-flex align-items-center">
    <el-dropdown class="mx-1" trigger="click" placement="bottom" @command="runMethod">
      <el-button type="text">
        <template v-if="user.profilePicture">
          <img :src="`${user.profilePicture}&token=${token}`" alt="" width="24" height="24" />
        </template>
        <template v-else>
          <div
            class="bg-primary rounded-pill d-flex justify-content-center align-items-center"
            style="height: 24px; width: 24px"
          >
            <small class="text-uppercase text-white fs-bold">{{ user.firstName[0] + user.lastName[0] }}</small>
          </div>
        </template>
      </el-button>
      <el-dropdown-menu slot="dropdown" class="mt-4">
        <el-dropdown-item command="openSettings">
          <p>{{ $t('layout.settings') }}</p>
        </el-dropdown-item>
        <el-dropdown-item command="signOut">
          <p>{{ $t('layout.signout') }}</p>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <p class="username">{{ `${user.firstName} ${user.lastName}` }}</p>
  </div>
</template>

<script>
import smartlookClient from 'smartlook-client';
import * as Sentry from '@sentry/browser';
import { computed } from 'vue';
import config from '@/config';

import { useUser } from '@/modules/auth';
import Auth from '@aws-amplify/auth';
import { apolloClient } from '@/plugins/apollo-client';

export default {
  name: 'ProfileBadge',
  setup() {
    const { user, loading, token } = useUser();

    return {
      token,
      user: computed(() => {
        if (loading.value) return { firstName: '', lastName: '' };

        const {
          username: userEmail,
          profile: { firstName, lastName, profilePicture },
        } = user.value;

        Sentry.setUser({ email: userEmail, id: user.value.id });
        if (config.NODE_ENV === 'production' && !smartlookClient.initialized()) {
          smartlookClient.init('858af2c7c2571a75f6610f24160a17a6364a83c5');
          smartlookClient.properties({ stage: process.env.NODE_ENV, userId: user.value.id });
          smartlookClient.identify(user.value.id, {
            email: userEmail,
            name: `${firstName} ${lastName}`,
          });
        }

        return { firstName, lastName, profilePicture };
      }),
    };
  },
  methods: {
    async runMethod(command) {
      switch (command) {
        case 'openSettings':
          this.openSettings();
          break;
        case 'signOut':
          await this.signOut();
          break;
      }
    },
    async signOut() {
      try {
        await this.$router.push({ name: 'signin' });
        await Auth.signOut();
        await apolloClient.cache.reset();
      } catch (error) {
        console.log('error signing out', error);
      }
    },
    openSettings() {
      this.$router.push({ name: 'settings' });
    },
  },
};
</script>
