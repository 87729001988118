<template>
  <div>
    <el-popover
      placement="bottom-start"
      popper-class="notifications-popover text-typography-primary mt-5"
      :visible-arrow="false"
      @show="handleNotificationsPopoverOpen"
    >
      <Button
        slot="reference"
        type="text"
        class="notifications-bell-container position-relative text-typography-primary p-1 mx-4"
      >
        <BellOutlinesIcon v-if="unseenNotificationCount === 0" />
        <BellIcon v-else />
        <div
          v-if="unseenNotificationCount > 0"
          class="badge rounded-pill bg-danger notification-badge position-absolute top-0"
          :class="{ ['start-0']: $direction === 'ltr', ['end-0']: $direction === 'rtl' }"
        >
          {{ unseenNotificationCount > 99 ? '+99' : unseenNotificationCount }}
        </div>
      </Button>
      <div class="p-4">
        <h3>
          {{ $t('settings.notifications') }}
        </h3>
      </div>
      <div class="notifications-container overflow-auto">
        <Notification
          v-for="notification in notifications"
          :id="notification.id"
          :key="notification.id"
          :title="notification.title"
          :description="notification.description"
          :read="notification.read"
          :created-at="notification.createdAt"
          :domain="notification.domain"
          :data="notification.data"
          @notificationClicked="handleNotificationClick($event)"
        />
        <div v-if="!notifications.length" class="text-center align-middle p-4">
          {{ $t('notifications.noNotifications') }}
        </div>
      </div>
    </el-popover>
    <OrderModal v-if="orderActivity" :activity="orderActivity" @close="orderActivity = null" />
  </div>
</template>

<script>
import { useMutation } from '@vue/apollo-composable';
import { computed, ref, getCurrentInstance } from 'vue';

import { EventMapModal } from '@/modules/eventMapModal';
import { BellOutlinesIcon, BellIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import { Button } from '@/modules/core';

import Notification from './Notification';
import { useNotifications } from '../../core/compositions/notifications/useNotifications';
import {
  NOTIFICATION_MARK_AS_READ_MUTATION,
  NOTIFICATION_MARK_AS_SEEN_MUTATION,
  UNREAD_NOTIFICATION_COUNT_BY_BUSINESS_QUERY,
} from '../../core/compositions/notifications/graphql';

export default {
  name: 'NotificationsBadge',
  components: { BellIcon, BellOutlinesIcon, OrderModal: EventMapModal, Notification, Button },
  setup() {
    const { $i18n, $message } = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();

    const { notifications: retrievedNotifications, refetch } = useNotifications(
      computed(() => $i18n.locale),
      computed(() => currentTenant.value?.id)
    );
    const { mutate: markNotificationAsRead } = useMutation(NOTIFICATION_MARK_AS_READ_MUTATION);
    const { mutate: markNotificationsAsSeen } = useMutation(NOTIFICATION_MARK_AS_SEEN_MUTATION, {
      update: (cache) => {
        const { notificationCountByBusiness } = cache.readQuery({
          query: UNREAD_NOTIFICATION_COUNT_BY_BUSINESS_QUERY,
        });

        const relevantBusinessNotificationCount = notificationCountByBusiness.find(
          (businessCount) => businessCount.businessId === currentTenant.value.id
        );
        const notificationCountsWithoutRelevantBusiness = notificationCountByBusiness.filter(
          ({ businessId }) => businessId !== relevantBusinessNotificationCount.businessId
        );

        cache.writeQuery({
          query: UNREAD_NOTIFICATION_COUNT_BY_BUSINESS_QUERY,
          data: {
            notificationCountByBusiness: [
              ...notificationCountsWithoutRelevantBusiness,
              { ...relevantBusinessNotificationCount, unseenNotificationCount: 0 },
            ],
          },
        });
      },
    });

    const orderActivity = ref(null);

    const handleNotificationClick = (notificationId) => {
      const notification = retrievedNotifications?.value.find((n) => n.id === notificationId);

      if (!notification.read)
        markNotificationAsRead({ notificationId }).catch((e) => {
          console.error(e); //TODO: add to logger when UI will have one
          $message.error($i18n.t('commons.messages.action.error'));
        });

      if (notification.domain === 'order') {
        orderActivity.value = { type: 'order', id: notification.data.orderId };
      }
    };

    return {
      refetchNotifications: refetch,
      notifications: computed(() =>
        [...retrievedNotifications.value].sort(
          (notificationA, notificationB) => notificationB.createdAt - notificationA.createdAt
        )
      ),
      unseenNotificationCount: computed(
        () => retrievedNotifications?.value.filter((notification) => !notification.seen).length
      ),
      handleNotificationClick,
      orderActivity,
      markNotificationsAsSeen,
      currentTenant,
    };
  },
  methods: {
    handleNotificationsPopoverOpen() {
      const newestUnseenNotification = this.notifications.filter(({ seen }) => !seen)[0];
      if (newestUnseenNotification) {
        this.markNotificationsAsSeen({
          date: newestUnseenNotification.createdAt,
          businessId: this.currentTenant.id,
        }).then(() => this.refetchNotifications());
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.notifications-bell-container {
  &:hover {
    background: $secondary;
  }

  &:focus {
    background: $secondary;
  }
}

.notifications-container {
  width: 25.5rem;
  max-height: 21.25rem;
}
</style>
