<template>
  <div class="shadow navigation-menu position-relative" :class="{ expanded }">
    <div class="pt-7 w-100">
      <MenuLink v-for="link in links" :key="link.route" v-bind="link" :expanded="expanded" />
    </div>
    <div
      class="navigation-menu-toggle position-absolute top-0"
      :class="{ ['start-0']: $direction === 'rtl', ['end-0']: $direction === 'ltr' }"
    >
      <Button
        type="text"
        class="text-typography-primary position-absolute rounded-pill bg-white shadow-sm p-0"
        @click="handleExpandToggle"
      >
        <ChevronIcon :direction="chevronDirection" />
      </Button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

import { useTenancy, useUser } from '@/modules/auth';
import { Button } from '@/modules/core';
import { useCurrency } from '@/locale/useCurrency';
import {
  ChevronIcon,
  AccountingIcon,
  AgreementRecordingIcon,
  BookkeepingIcon,
  DataTransferIcon,
  DocumentRecordingIcon,
  DocumentsIcon,
  OngoingActivityIcon,
  PaymentsNisIcon,
  PaymentsUsdIcon,
  ReceiptsAndReturnsIcon,
  SettingsIcon,
  TermsAndAgreementsIcon,
  DocumentClassificationIcon,
  DocumentOrganizationFolderIcon,
  ContactsIcon,
  PriceIndexIcon,
  PermissionsIcon,
  PodiumIcon,
  StoreIcon,
  RestaurantIcon,
  OrderNavigationIcon,
  SupplierIcon,
  AutomationIcon,
  ProductsIcon,
  OperationsIcon,
  RequestIcon,
  UserGroupIcon,
  TasksIcon,
} from '@/assets/icons';

import MenuLink from './MenuLink';
import { BUSINESS_TYPES, BUSINESS_TYPES_NORMALIZED } from '@/modules/permissions/pages/BusinessRelations';

import { useUserPermissions } from '@/modules/auth/compositions';
import { USER_PERMISSIONS_LIST, USER_NEW_PERMISSIONS_LIST } from '@/permissions';
import config from '@/config';

export const ALLOWED_ORDERS_BUSINESS_IDS = [
  '64ad31defbc905b5b74f24ea',
  '64ad2f72f158b7c1c2f43374',
  '6677d281a502c1ac330861ab',
  'whtp6dPqnwB9wCmMe',
];

const LINKS = [
  { route: 'clarity.businesses', icon: StoreIcon, text: 'businesses', capabilities: [BUSINESS_TYPES.serviceProvider] },
  { route: 'clarity.users', icon: UserGroupIcon, text: 'users', capabilities: [BUSINESS_TYPES.serviceProvider] },
  {
    route: 'clarity.restaurants',
    icon: RestaurantIcon,
    text: 'restaurants',
    capabilities: [BUSINESS_TYPES.serviceProvider],
  },
  { route: 'clarity.suppliers', icon: SupplierIcon, text: 'suppliers', capabilities: [BUSINESS_TYPES.serviceProvider] },
  {
    route: 'clarity.documents',
    icon: DocumentsIcon,
    text: 'documents',
    capabilities: [BUSINESS_TYPES.serviceProvider],
  },
  {
    route: 'automationManagement',
    icon: AutomationIcon,
    text: 'automationManagement',
    capabilities: [BUSINESS_TYPES.serviceProvider],
  },
  { route: 'clarity.products', icon: ProductsIcon, text: 'products', capabilities: [BUSINESS_TYPES.serviceProvider] },
  {
    route: 'clarity.priceIndexes',
    icon: PriceIndexIcon,
    text: 'price indexes',
    capabilities: [BUSINESS_TYPES.serviceProvider],
  },
  {
    route: 'suppliersPurchaseManagement',
    text: 'routes.suppliersPurchaseManagement',
    icon: TermsAndAgreementsIcon,
    capabilities: [BUSINESS_TYPES.restaurant],
    permissions: [USER_NEW_PERMISSIONS_LIST.VIEW_PURCHASE_MANAGEMENT],
    subRoutes: [
      {
        route: 'priceChangeImplications',
        text: 'routes.priceChangeImplications',
        icon: PriceIndexIcon,
        capabilities: [BUSINESS_TYPES.restaurant],
      },
      {
        route: 'topPurchasedProducts',
        text: 'routes.mostPurchased',
        icon: PodiumIcon,
        capabilities: [BUSINESS_TYPES.restaurant],
      },
      {
        route: 'contractRecording',
        text: 'contract recording',
        icon: AgreementRecordingIcon,
        capabilities: [BUSINESS_TYPES.restaurant],
        requireAdmin: true,
      },
    ],
  },
  {
    route: 'clarity.contactSets',
    text: 'routes.contactSets',
    icon: ContactsIcon,
    capabilities: [BUSINESS_TYPES.serviceProvider],
  },
  {
    route: 'activity',
    text: 'routes.activity',
    icon: OngoingActivityIcon,
    capabilities: [BUSINESS_TYPES.restaurant],
    requireAdmin: true,
    subRoutes: [
      {
        route: 'operation-management',
        text: 'routes.operationManagement',
        icon: OngoingActivityIcon,
        capabilities: [BUSINESS_TYPES.restaurant],
      },
    ],
  },
  {
    route: 'documents',
    text: 'routes.documents',
    icon: DocumentsIcon,
    capabilities: [BUSINESS_TYPES.restaurant],
    permissions: [USER_PERMISSIONS_LIST['document.view']],
    subRoutes: [
      {
        route: 'organizeDocuments',
        text: 'routes.organizeDocuments',
        icon: DocumentOrganizationFolderIcon,
        capabilities: [BUSINESS_TYPES.restaurant],
        requireAdmin: true,
      },
      {
        route: 'classifyDocuments',
        text: 'routes.classifyDocuments',
        icon: DocumentClassificationIcon,
        capabilities: [BUSINESS_TYPES.restaurant],
        requireAdmin: true,
      },
      {
        route: 'recordDocuments',
        text: 'routes.recordDocuments',
        icon: DocumentRecordingIcon,
        capabilities: [BUSINESS_TYPES.restaurant],
        requireAdmin: true,
      },
    ],
  },
  {
    route: 'deliveryManagement',
    text: 'routes.deliveryManagement',
    icon: ReceiptsAndReturnsIcon,
    capabilities: [BUSINESS_TYPES.restaurant],
    requireAdmin: true,
  },
  { route: 'priceListsManagement', text: 'routes.priceListsManagement', capabilities: [] },
  {
    route: 'operations',
    icon: OperationsIcon,
    text: 'routes.operations',
    capabilities: [BUSINESS_TYPES.serviceProvider],
    requireAdmin: true,
    subRoutes: [
      {
        route: 'requests',
        icon: RequestIcon,
        text: 'routes.requests',
        capabilities: [BUSINESS_TYPES.serviceProvider],
        requireAdmin: true,
      },
      {
        route: 'clarity.tasks',
        icon: TasksIcon,
        text: 'routes.tasks',
        capabilities: [BUSINESS_TYPES.serviceProvider],
        requireAdmin: true,
      },
    ],
  },
  {
    route: 'billingManagement',
    text: 'routes.billingManagement',
    icon: AccountingIcon,
    capabilities: [BUSINESS_TYPES.restaurant],
    requireAdmin: true,
  },
  {
    route: 'clarity.products-supplier',
    icon: ProductsIcon,
    text: 'productsSupplier',
    capabilities: [BUSINESS_TYPES.supplier],
  },
  {
    route: 'payments',
    nisIcon: PaymentsNisIcon,
    usdIcon: PaymentsUsdIcon,
    text: 'routes.payments',
    capabilities: [BUSINESS_TYPES.restaurant, BUSINESS_TYPES.supplier],
    permissions: [USER_PERMISSIONS_LIST.PAYMENT.VIEW],
    requireAdmin: false,
  },
  {
    route: 'requests-supplier',
    icon: RequestIcon,
    text: 'routes.requests',
    capabilities: [BUSINESS_TYPES.supplier],
  },
  {
    route: 'orders',
    text: 'routes.orders',
    icon: OrderNavigationIcon,
    capabilities: [BUSINESS_TYPES.restaurant],
    permissions: [USER_NEW_PERMISSIONS_LIST.ORDER_PROCESS],
    allowedBusinesses: ALLOWED_ORDERS_BUSINESS_IDS,
  },
  {
    route: 'bookkeepingManagement',
    text: 'routes.bookkeepingManagement',
    icon: BookkeepingIcon,
    capabilities: [BUSINESS_TYPES.restaurant],
    requireAdmin: true,
  },
  {
    route: 'bookkeeperDataTransferSummary',
    text: 'routes.dataTransfer',
    icon: DataTransferIcon,
    capabilities: [BUSINESS_TYPES.bookkeeping],
  },
  {
    route: 'contacts',
    text: 'routes.contacts',
    icon: ContactsIcon,
    capabilities: [BUSINESS_TYPES.restaurant],
    permissions: [USER_NEW_PERMISSIONS_LIST.VIEW_DOCUMENT],
  },
  {
    route: 'permissions',
    text: 'routes.permissions',
    icon: PermissionsIcon,
    capabilities: [
      BUSINESS_TYPES.restaurant,
      BUSINESS_TYPES.bookkeeping,
      BUSINESS_TYPES.serviceProvider,
      BUSINESS_TYPES.supplier,
    ],
    permissions: [USER_NEW_PERMISSIONS_LIST.VIEW_PERMISSIONS],
  },
  {
    route: 'businessSettings',
    text: 'routes.settings',
    icon: SettingsIcon,
    capabilities: [BUSINESS_TYPES.restaurant, BUSINESS_TYPES.supplier],
    permissions: [USER_NEW_PERMISSIONS_LIST.VIEW_BUSINESS_SETTINGS],
  },
];

export default {
  components: { MenuLink, ChevronIcon, Button },
  setup() {
    const { currentTenant } = useTenancy();
    const { isAdmin, user } = useUser();
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const { currencyFormat } = useCurrency();

    const isNis = computed(() => currencyFormat.value.currency === 'ILS');
    return {
      expanded: ref(true),
      isAdmin,
      currentTenant,
      user,
      isUserPermittedForActiveTenant,
      isNis,
    };
  },
  computed: {
    links() {
      return LINKS.map((link) => {
        let icon;
        if (link.icon) icon = link.icon;
        else if (link.nisIcon && link.usdIcon) {
          icon = this.isNis ? link.nisIcon : link.usdIcon;
        }
        return {
          ...link,
          icon,
          subRoutes: link.subRoutes
            ?.filter((innerLink) => !innerLink.requireAdmin || this.isAdmin)
            .filter(({ capabilities }) => this.isLinkAllowedForBusiness(capabilities, this.currentTenant.capabilities))
            .filter(({ featureFlag }) => this.isLinkAllowedFeatureFlag(featureFlag)),
        };
      })
        .filter((link) => !link.requireAdmin || this.isAdmin)
        .filter(({ capabilities }) => this.isLinkAllowedForBusiness(capabilities, this.currentTenant.capabilities))
        .filter(({ permissions, route }) => {
          // Supplier payments page temporary solution (Hack)
          if (route === 'payments' && this.isAdmin) return true;
          return permissions
            ? permissions.some((permission) => this.isUserPermittedForActiveTenant(permission).value)
            : true;
        })
        .filter(({ featureFlag }) => this.isLinkAllowedFeatureFlag(featureFlag))
        .filter(({ route, allowedBusinesses }) => this.isLinkAllowedForActiveTenant(route, allowedBusinesses));
    },
    chevronDirection() {
      if (this.$direction === 'rtl') return this.expanded ? 'right' : 'left';
      return this.expanded ? 'left' : 'right';
    },
  },
  methods: {
    handleExpandToggle() {
      this.expanded = !this.expanded;
    },
    isLinkAllowedForBusiness(linkCapabilities, businessCapabilities) {
      const allowedBusinessTypeByBusinessCapabilities = [];
      Object.keys(businessCapabilities).forEach((capability) => {
        if (businessCapabilities[capability] === true) {
          let businessType = BUSINESS_TYPES.restaurant;
          if (capability === BUSINESS_TYPES_NORMALIZED.SERVICE_PROVIDER) {
            businessType = BUSINESS_TYPES.serviceProvider;
          } else if (capability === BUSINESS_TYPES_NORMALIZED.SUPPLIER) {
            businessType = BUSINESS_TYPES.supplier;
          } else if (capability === BUSINESS_TYPES_NORMALIZED.BOOK_KEEPING) {
            businessType = BUSINESS_TYPES.bookkeeping;
          }
          allowedBusinessTypeByBusinessCapabilities.push(businessType);
        }
      });
      for (let i = 0; i < allowedBusinessTypeByBusinessCapabilities.length; i++) {
        const allowedBusiness = allowedBusinessTypeByBusinessCapabilities[i];
        if (linkCapabilities.includes(allowedBusiness)) {
          return true;
        }
      }
      return false;
    },
    isLinkAllowedFeatureFlag(featureFlag) {
      if (!featureFlag) return true;
      return config[featureFlag]?.toLowerCase() === 'true';
    },
    isLinkAllowedForActiveTenant(link, businessesToCheck) {
      if (link === 'orders' && businessesToCheck?.length) {
        if (businessesToCheck.includes(this.currentTenant.id)) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.navigation-menu {
  width: $navigation-menu-width;
  transition: width 0.4s cubic-bezier(0.34, 0.16, 0.57, 0.88);
  $toggle-line-width: 0.125rem;
  $trigger-area-width: 0.5rem;

  .navigation-menu-toggle {
    opacity: 1;
    margin: 0.75rem;
    z-index: 2001;

    button:hover {
      color: $white !important;
      background: $primary !important;
    }
  }

  &.expanded {
    width: $navigation-menu-width-expanded;
    transition: width 0.4s cubic-bezier(0.34, 0.16, 0.57, 0.88);

    .navigation-menu-toggle {
      opacity: 0;
    }

    &:hover {
      .navigation-menu-toggle {
        opacity: 1 !important;
      }
    }
  }
}
</style>
